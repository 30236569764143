import React, { useCallback, useEffect, useState } from 'react';
import { Wrapper } from './styles';
import { Menu, simpleScrollTo } from '../../../components/Menu';
import { usePages } from '../../../hooks/Pages';
import FooterLayout from '../../Footer';
import { useAuth } from '../../../hooks/Auth';
import PopupComponent from '../../../components/PopupContainer';
import Helmet from 'react-helmet';
import Particles from 'react-particles-js';
import ImageSliderBackground from '../../../components/ImageBackgroundSlider';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useConfiguration } from '../../../hooks/Configuration';
import api from '../../../services/api';
import Loader from '../../DashboardPages/core/components/Loader';
import { hash, urlWebsite } from '../../../config';
import { useSocket } from '../../../hooks/Socket';
import { useVideo } from '../../../hooks/Video';
import FormContainerUpdate from '../../SignUpUpdate/FormContainer';
import { useModal } from '../../../hooks/Modal';
import { addDays } from 'date-fns/esm';
import { useToast } from '../../../hooks/Toast';
import { loadFromJson } from '../../../utils/loadFromJson';
import { useLanguage } from '../../../hooks/Language';
import { useLoading } from '../../../hooks/LoadingHook';
import WhatsappFixed from '../../../components/WhatsappFloat';
import { FaChevronRight, FaQrcode, FaTicketAlt } from 'react-icons/fa';
import ContagemRegressivaMini from '../../../components/ContagemRegressivaMini';
import { scrollToID } from '../../../utils/scrollTo';
import LeadCapture from '../../../PagesApplication/HomeApplication/modules/ButtonLeadCapture';

interface IParams {
  projectHash: string;
  eventHash: string;
}

interface IMenu {
  title: string;
  target: string;
  targetID: string;
  fluid: number;
  external: number;
  status: number;
}

const DefaultLayout: React.FC = ({ children }) => {
  const page = window.location.pathname !== '/';
  const { menu, setMenu, prepareMenu } = usePages();

  const { token, user, handleApiErrors, signOut, setTrackingRecommendation, setTrackingMarketing, setTrackingCupom } = useAuth();
  const { configuration, setConfiguration } = useConfiguration();

  const [loading, setLoading] = useState(false);
  const [ready, setReady] = useState(false);

  const { addModal, removeModal } = useModal();

  const { addToast } = useToast();


  const { projectHash, eventHash } = useParams<IParams>();



  const { setAvailableLanguages } = useLanguage();

  const [slides, setSlides] = useState<Array<JSX.Element>>([]);

  const { addLoading, removeLoading } = useLoading();

  const { search } = useLocation();
  const params = new URLSearchParams(search);





  const load = async (projectHashValue) => {
    // setLoading(true);

    try {


      let data: Record<string, any> = {};

      const project = await loadFromJson({ hash: hash, project_id: projectHashValue, table: 'Project' });

      if (project?.[0]) {
        data = project[0];

        const event = await loadFromJson({ hash: hash, project_id: data.url, table: 'Event' });
        if (event?.[0]) {


          data.current_event_id_response = { ...event[0] };
        }
        else {

          const response = await api.get(eventHash ? `/get-project-content/${projectHashValue}/${eventHash}` : `/get-project-content/${projectHashValue}`);
          data = response?.data;
        }
      }
      else {

        const response = await api.get(eventHash ? `/get-project-content/${projectHashValue}/${eventHash}` : `/get-project-content/${projectHashValue}`);

        data = response?.data;
      }



      setConfiguration(data);



      if (data?.active_pages?.length > 0) {

        prepareMenu({ eventHash, projectHash: projectHashValue, active_pages: data?.active_pages });
      }



      setLoading(false);
      setReady(true);
      //setTimeout(() => { removeLoading('loading'); }, 1000)
    }
    catch (err) {

      handleApiErrors(err);
      setLoading(false);
      //  setTimeout(() => { removeLoading('loading'); }, 1000)

    }
  }

  const hashFragment = window.location.hash;

  useEffect(() => {


    if (hashFragment) {
      setTimeout(() => { simpleScrollTo(hashFragment?.replace('#', '')); }, 500)
    }

  }, [hashFragment])

  const loadConfig = async () => {
    // addLoading({ title: 'Carregando', key: 'loading' })
    let data: Record<string, any> = {};

    const item = await loadFromJson({ hash: hash, project_id: 'global', table: 'Global' })


    if (!item) {
      const config = await api.get('/get_platform');

      data = config.data;
    }
    else {
      data = item?.[0] || {}
    }


    if (data.slides) {
      const slideJSX: Array<JSX.Element> = [];

      data.slides.map(s => {
        slideJSX.push(s.link_url ? <a href={s.link_url}><img src={`${urlWebsite}/${s.url}`} style={{ width: '100%' }} /></a> : <img src={`${urlWebsite}/${s.url}`} style={{ width: '100%' }} />)
      })

      setSlides(slideJSX);

      if (data?.popup_status === 'yes' && data?.popup_images && data?.popup_images?.length > 0) {

        if (user && user.updated_information !== 'yes' && user.profile === 2) {

        }
        else {
          addModal({
            key: 'popup',
            theme: 'whiteModalCapa',
            title: ' ',
            content: <><div style={{ width: '100%', maxWidth: '700px', margin: '0px auto' }}>
              <h2 style={{ padding: '10px', width: '100%', textAlign: 'center', color: 'rgb(0,14,64)' }}>Clique na imagem para acessar</h2>
              {data?.popup_images.map(popup => {
                return <a onClick={() => removeModal('popup')} key={`popup-url-${popup.url}`} style={{ width: '100%', maxWidth: '700px', margin: '15px auto' }} href={`${popup?.link_url}`}>
                  <img src={`${urlWebsite}/${popup.url}`} style={{ width: '100%', maxWidth: '700px', margin: '0px auto' }} />
                </a>

              })}

            </div></>
          })

        }


      }

    }

    const menu: Array<IMenu> = [];

    try {
      data?.menu.map((m: IMenu) => {
        m.fluid = parseInt(m.fluid.toString(), 10);
        m.status = parseInt(m.status.toString(), 10);
        m.external = parseInt(m.external.toString(), 10);

        menu.push(m);
      })

      setMenu(menu);

    }
    catch (err) {
      console.log('Error on define Menu');
    }


    setAvailableLanguages(data?.languages || []);
    // data.default_project = 'evento'

    if (data?.default_project && !projectHash) {
      load(data?.default_project)
    }
    else {
      load(projectHash);
    }

  }

  useEffect(() => {
    loadConfig();


  }, [projectHash])

  useEffect(() => {

    const tracking = params.get("indicado-por");

    if (tracking) {
      setTrackingRecommendation(tracking);
    }


    const marketing_params: Record<string, any> = {
      "src": params.get("src") || '',
      "utm_source": params.get("utm_source") || '',
      "utm_medium": params.get("utm_medium") || '',
      "utm_content": params.get("utm_content") || '',
      "utm_campaign": params.get("utm_campaign") || '',
      "utm_term": params.get("utm_term") || ''
    }

    if (marketing_params?.src || marketing_params?.utm_source || marketing_params?.utm_campaign) {
      setTrackingMarketing(marketing_params);
    }





  }, [])

  useEffect(() => {

    const cupom = params.get("cupom");

    if (cupom) {
      setTrackingCupom(cupom);
    }


  }, [])


  useEffect(() => {

    if (user && !user.last_login) {
      signOut();
      window.location.reload();
    }

    if (user && user?.last_login) {

      const checkDate = addDays(new Date(), -5);
      const userDate = new Date(user.last_login);
      if (checkDate > userDate) {
        addToast({ title: 'Seu acesso expirou, faça seu login novamente', type: 'info' });

        setTimeout(() => {
          signOut();
          window.location.reload();
        }, 2000);
        return;
      }


    }

    {/*   if (user && user?.profile === 2 && user?.updated_information !== 'yes' && user?.updated_information !== 'no') {
      signOut();
      window.location.reload();
      return;
    }
  */}




  }, [user]);



  const indexOf = window?.location?.pathname.indexOf('dashboard');
  const { activeByModal, setActiveByModal } = useAuth();
  const checkLeadForm = (buttonInfo: Record<string, any>) => {
    setActiveByModal(true)
    addModal({ title: '', theme: 'whiteModalMedium', content: <LeadCapture coupon={buttonInfo?.coupon} /> });
    if (buttonInfo?.id_target) {
      scrollToID(buttonInfo?.id_target)
    }

  }

  return (
    <>
      {loading && <Loader message='Carregando' />}
      <Wrapper background_color={indexOf >= 0 ? '' : configuration.background_color}  >
        {configuration?.background ? <div className='bgImage' style={{ background: `url("${urlWebsite}/${configuration.background}")`, backgroundSize: 'cover', position: 'fixed', opacity: 0.3, top: 0, left: 0, bottom: 0, right: 0, zIndex: 0 }} /> : <></>}

        {configuration?.url ? <>
          <div style={{ width: '100%', height: '60px', position: 'relative', zIndex: 100000, boxShadow: '0px 5px 15px rgba(0,0,0,0.3)' }}>
            <div style={{ position: 'fixed', height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px', background: 'rgb(2, 28, 60)', width: '100%', top: '0px', left: '0px' }}>
              <div className='row gap' style={{ justifyContent: 'center', width: '100%', alignItems: 'center', maxWidth: '1200px' }}>
                {window?.location?.pathname?.indexOf('inscricao') < 0 && window?.location?.pathname?.indexOf('success') < 0 ? <a href={`/app/${configuration?.url}/login`}><button className="highlightButtonWhite2 topCall"><FaQrcode /><div className='column-start'> <h2 style={{ fontSize: '20px', textAlign: 'left' }}>Meus ingressos</h2>  </div></button></a> : <></>}


                {/* <ContagemRegressivaMini /> */}


              </div>
            </div>
          </div>

        </> : <Menu menuList={menu} fixedMenu={false} />}
        {ready && <>
          {children}

          <PopupComponent />


        </>}
      </Wrapper>
      <WhatsappFixed number='5551993979013' text={`Olá, estou no Portal Everaldo Rodrigues${configuration?.title ? ` - ${configuration?.title}` : ''}, pode me ajudar?`} />
      <FooterLayout />
      {configuration?.fixed_image_header && <img src={`${urlWebsite}/${configuration.fixed_image_header}`} className="fixedBottom" />}

      <Helmet>
        {configuration?.title && projectHash && <title>{`${configuration.title} `}</title>}

      </Helmet>

    </>
  );
};

export default DefaultLayout;
